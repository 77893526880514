<template lang="pug">
.contact-page
	h1 Contact
</template>

<script>
export default {
	name: "About"
}
</script>

<style lang="scss" scoped>
.contact-page {
	h1 {
		text-align: center;
	}
}
</style>

